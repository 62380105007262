import React from 'react';
import PropTypes from 'prop-types';


class EditFishForm extends React.Component {
    static propTypes = {
        deleteFish: PropTypes.func.isRequired,
        fish: PropTypes.shape({
            name: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            desc: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired
        }).isRequired,
        index: PropTypes.string.isRequired,
        updateFish: PropTypes.func.isRequired,
    }
    handleChange = (event) => {
        console.log(event.currentTarget.value);
        // update that fish
        // 1. Take a copy of the current fish
        const updatedFish = {
            ...this.props.fish,
            [event.currentTarget.name]: event.currentTarget.value
        }; 
        // [event.currentTarget.name] --> ES6 allows you to use square brackets to dynamically select what property is updated
        // the event object has a currentTarget property, which in turn has a name property, which we've defined in the render method 
        // 
        this.props.updateFish(this.props.index, updatedFish);
    }
    render() {
        return <div className="fish-edit">
            <input type="text" 
                name="name" 
                onChange={this.handleChange} 
                value={this.props.fish.name} /> 
            <input type="text" 
                name="price" 
                onChange={this.handleChange} 
                value={this.props.fish.price} />
            <select type="text" 
                name="status" 
                onChange={this.handleChange} 
                value={this.props.fish.status}>
                <option value="available">Fresh!</option>
                <option value="unavailable">Sold out</option>
            </select>
            <textarea type="text" 
                name= "desc" 
                onChange={this.handleChange} 
                value={this.props.fish.desc} />
            <input type="text" 
                name= "image" 
                onChange={this.handleChange} 
                value={this.props.fish.image} />
            <button onClick={() => {this.props.deleteFish(this.props.index)}}>Remove Fish</button>
        </div>
    }
}

export default EditFishForm;