import Rebase from 're-base'; // for mirroring our state from firebase to app
import firebase from 'firebase' // for everything outside of the mirroring

const firebaseApp = firebase.initializeApp({
    
    apiKey: "AIzaSyCGog2a1tcB9z7a7W2GhQJv2hLhfctrxOw",
    authDomain: "catch-of-the-day-jaymesonpon.firebaseapp.com",
    databaseURL: "https://catch-of-the-day-jaymesonpon-default-rtdb.firebaseio.com"
})

const base = Rebase.createClass(firebaseApp.database());

// This is a named export
export {firebaseApp };

//  this is a default export
export default base;