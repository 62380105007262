import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import AddFishForm from './AddFishForm.js';
import EditFishForm from './EditFishForm';
import Login from './Login';
import base, { firebaseApp } from '../base';

class Inventory extends React.Component {
    static propTypes = {
        // instead of shape, you could just say PropTypes.object, but using object will be less stringent
        fishes: PropTypes.shape({
            fish: PropTypes.element
        }),
        addFish: PropTypes.func.isRequired,
        deleteFish: PropTypes.func.isRequired,
        loadSampleFishes: PropTypes.func,
        updateFish: PropTypes.func.isRequired,
    };

    state = {
        uid: null,
        owner: null
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            if(user) {
                this.authHandler({ user });
            }
        })
    }

    authHandler = async (authData) => {
        // 1. Look up the current store in the firebase database
        const store = await base.fetch(this.props.storeId, {context: this});
        console.log(store);
        // 2. Claim it if there is no owner
        if (!store.owner) {
            await base.post(`${this.props.storeId}/owner`, {
                data: authData.user.uid
            })
        }
        // 3. Set the state of the inventory component to reflect the current user
        this.setState({
            uid: authData.user.uid,
            owner: store.owner || authData.user.uid
        })
    }

    authenticate = (provider) => {
        const authProvider = new firebase.auth[`${provider}AuthProvider`]();
        firebaseApp
            .auth()
            .signInWithPopup(authProvider)
            .then(this.authHandler)
    }

    logout = async () => {
        console.log(`Logging out!`);
        await firebase.auth().signOut();
        this.setState({ uid: null})
    }
    render() {
        const logout = <button onClick={this.logout}>Logout</button>

        // 1. Check if they are logged in
        if (!this.state.uid) {
            return <Login authenticate={this.authenticate}/>;
        }

        // 2. check if they are not the owner of the store
        if (this.state.uid !== this.state.owner) {
            return <div>
                <p>Sorry! You are not the owner</p>
                {logout}
            </div>
        }
        // 3. They must be the onwer. Just render the inventory
        return (
            <div className='inventory'>
                <h2> Inventory!!!</h2>
                {logout}
                {Object.keys(this.props.fishes).map(key => 
                <EditFishForm 
                    key={key} 
                    fish={this.props.fishes[key]}
                    index={key}
                    updateFish={this.props.updateFish}
                    deleteFish={this.props.deleteFish}
                />)}
                <AddFishForm 
                    addFish={this.props.addFish}
                    updateFish={this.props.updateFish}
                />
                <button onClick={this.props.loadSampleFishes}>Load Sample Fishes</button>
            </div>
        );
    }
}

export default Inventory;