import React from 'react';
import PropTypes from 'prop-types';
import {getFunName} from '../helpers';

// state === just an object that holds data that itself and possibly its children need. It's a single source of truth

/*note --> if we call the eventhandler or function with (), then it will run when the component mounts. If you're calling an event handler, then you don't use () because
you want it to run when the event happens*/

class StorePicker extends React.Component {

    static propTypes = {
        history: PropTypes.shape({
            action: PropTypes.string,
            createHref: PropTypes.func,
            go: PropTypes.func,
            goBack: PropTypes.func,
            goForward: PropTypes.func,
            length: PropTypes.number,
            listen: PropTypes.func
        })
    }

    // constructor() {
    //     super();
    //     this.goToStore = this.goToStore.bind(this);
    //  can use a constructor so you can call the bind function to bind custom functions to this instance
    // }

    myInput = React.createRef();

    goToStore = event => {
        /* 1. stop form from submitting */
        event.preventDefault();
        /* 2. get the text from that input */
        const storeName = this.myInput.current.value; 
        /* 3. Change the page to /store/  */
        this.props.history.push(`/store/${storeName}`);
    }

    render() {
        return ( 
                <form className="store-selector" onSubmit={this.goToStore}>
                    <h2>Please Enter A Store</h2>

                    <input 
                        type="text" 
                        ref={this.myInput}
                        required placeholder="Store Name"
                        defaultValue={getFunName()} /> 
                    <button type="submit">Visit Store</button>
                </form>
        )
    };
}

export default StorePicker;